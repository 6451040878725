var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "apartment-detail" },
    [
      _vm.title
        ? _c("app-header", { attrs: { title: _vm.title, isShowBack: true } })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "apartment-form" },
        [
          _c(
            "el-form",
            { ref: "myForm", attrs: { model: _vm.myForm, rules: _vm.rules } },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        label: "房源类型",
                        prop: "type"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择房源类型",
                            filterable: "",
                            disabled: _vm.notEdit || _vm.partNotEdit
                          },
                          on: {
                            change: function($event) {
                              _vm.onChangeSelect(_vm.myForm.type)
                            }
                          },
                          model: {
                            value: _vm.myForm.type,
                            callback: function($$v) {
                              _vm.$set(_vm.myForm, "type", $$v)
                            },
                            expression: "myForm.type"
                          }
                        },
                        _vm._l(_vm.apartmentTypeList, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.myForm.type == 7
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { "label-width": "120px", label: "选择学校" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择学校",
                                disabled: _vm.notEdit || _vm.partNotEdit
                              },
                              model: {
                                value: _vm.myForm.apartmentCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.myForm, "apartmentCode", $$v)
                                },
                                expression: "myForm.apartmentCode"
                              }
                            },
                            _vm._l(_vm.schoolList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        label: _vm.nameType,
                        prop: "name"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "item_width",
                        attrs: {
                          disabled: _vm.notEdit || _vm.partNotEdit,
                          placeholder: _vm.nameTypePlaceholder
                        },
                        model: {
                          value: _vm.myForm.name,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.myForm,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "myForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        label: "省份",
                        prop: "province"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: "请选择省份",
                            disabled: _vm.notEdit || _vm.partNotEdit
                          },
                          on: {
                            change: function($event) {
                              _vm.getCityList(_vm.myForm.province)
                            }
                          },
                          model: {
                            value: _vm.myForm.province,
                            callback: function($$v) {
                              _vm.$set(_vm.myForm, "province", $$v)
                            },
                            expression: "myForm.province"
                          }
                        },
                        _vm._l(_vm.provinceList, function(it) {
                          return _c("el-option", {
                            key: it.value,
                            attrs: { label: it.label, value: it.label }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        label: "城市",
                        prop: "city"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: "请选择城市",
                            disabled: _vm.notEdit || _vm.partNotEdit
                          },
                          on: {
                            change: function($event) {
                              _vm.getDistrictList(_vm.myForm.city)
                            }
                          },
                          model: {
                            value: _vm.myForm.city,
                            callback: function($$v) {
                              _vm.$set(_vm.myForm, "city", $$v)
                            },
                            expression: "myForm.city"
                          }
                        },
                        _vm._l(_vm.cityList, function(it) {
                          return _c("el-option", {
                            key: it.value,
                            attrs: { label: it.label, value: it.label }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        label: "县区",
                        prop: "district"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: "请选择县区",
                            disabled: _vm.notEdit || _vm.partNotEdit
                          },
                          on: {
                            change: function($event) {
                              _vm.getStreetList(_vm.myForm.district)
                            }
                          },
                          model: {
                            value: _vm.myForm.district,
                            callback: function($$v) {
                              _vm.$set(_vm.myForm, "district", $$v)
                            },
                            expression: "myForm.district"
                          }
                        },
                        _vm._l(_vm.districtList, function(it) {
                          return _c("el-option", {
                            key: it.value,
                            attrs: { label: it.label, value: it.label }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "120px", label: "街道" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: "请输入街道",
                            disabled: _vm.notEdit || _vm.partNotEdit
                          },
                          model: {
                            value: _vm.myForm.street,
                            callback: function($$v) {
                              _vm.$set(_vm.myForm, "street", $$v)
                            },
                            expression: "myForm.street"
                          }
                        },
                        _vm._l(_vm.streetList, function(it) {
                          return _c("el-option", {
                            key: it.value,
                            attrs: { label: it.label, value: it.label }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        label: "楼栋数(栋)",
                        prop: "buildings"
                      }
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          type: "number",
                          min: _vm.minVal,
                          disabled: _vm.notEdit,
                          clearable: ""
                        },
                        on: {
                          change: function($event) {
                            _vm.numberChange(_vm.myForm.buildings)
                          }
                        },
                        model: {
                          value: _vm.myForm.buildings,
                          callback: function($$v) {
                            _vm.$set(_vm.myForm, "buildings", _vm._n($$v))
                          },
                          expression: "myForm.buildings"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "padding-bottom": "25px" } },
                _vm._l(_vm.buildingList, function(item, index) {
                  return _c("span", { key: item.buildCode }, [
                    _c("div", { staticStyle: { display: "inline-block" } }, [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#606266",
                                "font-size": "14px",
                                width: "114px",
                                "line-height": "32px",
                                "padding-right": "6px",
                                "text-align": "right"
                              }
                            },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*")
                              ]),
                              _vm._v("楼栋名称")
                            ]
                          ),
                          _c("el-input", {
                            staticStyle: {
                              display: "inline-block",
                              width: "217px"
                            },
                            attrs: {
                              minlength: "1",
                              maxlength: "8",
                              placeholder: "请输入楼栋名称",
                              disabled: _vm.notEdit
                            },
                            on: {
                              input: function($event) {
                                _vm.buidingNameChange(item, index)
                              }
                            },
                            model: {
                              value: item.buildName,
                              callback: function($$v) {
                                _vm.$set(
                                  item,
                                  "buildName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "item.buildName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#F56C6C",
                            "font-size": "12px",
                            "padding-left": "120px"
                          }
                        },
                        [_vm._v(_vm._s(item.errFlag ? "请输入楼栋名称" : " "))]
                      )
                    ])
                  ])
                }),
                0
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "120px", label: "房源负责人" } },
                [
                  _c("el-input", {
                    staticClass: "item_width",
                    attrs: {
                      disabled: _vm.notEdit,
                      placeholder: "请输入房源负责人"
                    },
                    model: {
                      value: _vm.myForm.regionManager,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "regionManager", $$v)
                      },
                      expression: "myForm.regionManager"
                    }
                  })
                ],
                1
              ),
              _vm.notEdit
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "120px",
                        label: "房东姓名",
                        placeholder: "请输入房东姓名"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "item_width",
                        attrs: { disabled: _vm.notEdit },
                        model: {
                          value: _vm.myForm.landlordName,
                          callback: function($$v) {
                            _vm.$set(_vm.myForm, "landlordName", $$v)
                          },
                          expression: "myForm.landlordName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "负责人电话",
                    prop: "managerPhoneNum"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "item_width",
                    attrs: {
                      disabled: _vm.notEdit,
                      placeholder: "请输入负责人电话"
                    },
                    model: {
                      value: _vm.myForm.managerPhoneNum,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "managerPhoneNum", $$v)
                      },
                      expression: "myForm.managerPhoneNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "120px", label: "房源停车场" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择房源停车场",
                        disabled: _vm.notEdit
                      },
                      model: {
                        value: _vm.myForm.carParkStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.myForm, "carParkStatus", $$v)
                        },
                        expression: "myForm.carParkStatus"
                      }
                    },
                    _vm._l(_vm.carParkStatusList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "92%" },
                  attrs: {
                    "label-width": "120px",
                    label: "详细地址",
                    prop: "address"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      id: "place",
                      maxlength: "200",
                      disabled: _vm.notEdit || _vm.partNotEdit,
                      placeholder: "请输入详细地址"
                    },
                    model: {
                      value: _vm.myForm.address,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "address", $$v)
                      },
                      expression: "myForm.address"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "92%" },
                  attrs: {
                    "label-width": "120px",
                    label: "标签",
                    prop: "labels"
                  }
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { size: "small", disabled: _vm.notEdit },
                      model: {
                        value: _vm.myForm.labels,
                        callback: function($$v) {
                          _vm.$set(_vm.myForm, "labels", $$v)
                        },
                        expression: "myForm.labels"
                      }
                    },
                    _vm._l(_vm.labelsList, function(it) {
                      return _c(
                        "el-checkbox",
                        { key: it.id, attrs: { label: it.id, border: "" } },
                        [_vm._v(_vm._s(it.name) + "\n                    ")]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "label-width": "120px",
                    label: "房源图片",
                    prop: "pictureUrlList"
                  }
                },
                [
                  !_vm.notEdit
                    ? _c(
                        "el-upload",
                        {
                          class: { "upload-disabled": _vm.notEdit },
                          attrs: {
                            accept: "image/*",
                            "list-type": "picture-card",
                            action: _vm.picUrl,
                            "file-list": _vm.myForm.pictureUrlList,
                            limit: 6,
                            name: "file",
                            "on-success": _vm.onSuccessByFormUploadImg(),
                            "on-remove": _vm.onHandleRemove(),
                            "before-remove": _vm.onBeforeRemoveByUploadImg(),
                            "on-preview": _vm.onPreviewByUploadImg,
                            "on-exceed": _vm.onExceed,
                            disabled: _vm.notEdit
                          }
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      )
                    : _vm._e(),
                  _vm.notEdit
                    ? _c(
                        "div",
                        { staticClass: "img-list" },
                        _vm._l(_vm.myForm.pictureList, function(it) {
                          return _c("img", {
                            key: it.src,
                            attrs: { src: it.src },
                            on: {
                              click: function($event) {
                                _vm.showBig(it.src)
                              }
                            }
                          })
                        }),
                        0
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.notEdit
                ? _c(
                    "el-form-item",
                    { attrs: { "label-width": "120px", label: "房源二维码" } },
                    [
                      _vm.notEdit
                        ? _c("div", { staticClass: "img" }, [
                            _c("img", {
                              attrs: { src: _vm.myForm.miniTwoCodeUrlStr },
                              on: {
                                click: function($event) {
                                  _vm.showBig(_vm.myForm.miniTwoCodeUrlStr)
                                }
                              }
                            })
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "92%" },
                  attrs: { "label-width": "120px", label: "房源简介" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "200",
                      type: "textarea",
                      rows: "6",
                      "show-word-limit": "",
                      placeholder: "请输入房源简介",
                      disabled: _vm.notEdit
                    },
                    model: {
                      value: _vm.myForm.description,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "description", $$v)
                      },
                      expression: "myForm.description"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.notEdit,
                      expression: "!notEdit"
                    }
                  ],
                  staticClass: "submitBox"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.submit()
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, width: "50%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }